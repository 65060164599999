@import 'variables';

/* Bootstrap palette */
$theme-colors: (
        success: $main-primary-color,
        danger: darkred,
        info: #9fc0e5,
        warning: #939346,
        secondary: #4f4f4f,
        light: #f8f9fa
);
@import 'bootstrap/scss/bootstrap';

.form-group {
  .required:after {
    content:"*";
    color: $red;
  }
}

.grabbing * {
  cursor: grabbing !important;
}

/* Boostrap overrides */
.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.6rem;
  --bs-btn-border-radius: 0.25rem;
}

/**
  Styles added by material

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
*/

.prevent-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/**
  Mat dialog overrides for preview
 */
.cdk-overlay-container {
  z-index: 1095 !important;
}
.mat-dialog-container {
  background-color: black;
  padding: 0 !important;
}

/**
  Override bootstrap checkbox style
 */
$checkbox-color: #fffafa;
$checkbox-background: $main-primary-color;
.form-check-input {
  &:checked {
    background-color: $checkbox-background !important;
    border: 0;
  }
  &:focus {
    color: $checkbox-color;
    outline: 0;
    border: 0;
    box-shadow: 0 0 0 0.1rem $checkbox-background !important;
  }
  &::after {
    color: $checkbox-color;
    outline: 0;
    border: 0;
    box-shadow: 0 0 0 0.1rem $checkbox-background !important;
  }
  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        &:focus {
          color: $checkbox-color;
          outline: 0;
          border: 0;
          box-shadow: 0 0 0 0.1rem $checkbox-background !important;
        }
      }
    }
  }
}
.label {
  &::after {
    color: $checkbox-color;
    outline: 0;
    border: 0;
    box-shadow: 0 0 0 0.1rem $checkbox-background !important;
  }
}
label.form-check-label {
  &:focus {
    color: $checkbox-color;
    outline: 0;
    border: 0;
    box-shadow: 0 0 0 0.1rem $checkbox-background !important;
  }
}
.tooltip-inner {
  text-align: left;
}
.link-primary {
  text-decoration: none;
}
.cursor-pointer {
  cursor: pointer;
}

/* Bootstrap tooltip */
.tooltip-custom .tooltip-inner {
  background-color: $main-primary-color;
  font-size: 0.7rem;
}
.tooltip-custom.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: $main-primary-color;
}
.tooltip-custom.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: $main-primary-color;
}
.tooltip-custom.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $main-primary-color;
}
.tooltip-custom.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $main-primary-color;
}

/* Bootstrap tabs */
.nav-tabs .nav-item .nav-link {
  color: $main-primary-color;
  font-size: 0.7rem;
}
.nav-tabs .nav-item .nav-link.active {
  color: $main-primary-color;
  font-weight: bold;
}
.drag-drop-handler {
  cursor: move;
}
.cdk-drag-preview {
  z-index: 9999 !important;
  background-color: white;
}
tr.cdk-drag-preview {
  display: flex;
  flex-direction: row;
  td {
    flex: 1;
  }
}
